


































import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { ChapterImage } from '@/models';
import { Encrypto } from '@/services';
import LoadingAnimation from './LoadingAnimation.vue';
import { isPC } from '@/services/common/utils';
import AlertDialog from '@/dialogs/AlertDialog.vue';

const convertPxToVw = (value, width = 720) => {
  const rate = 100 / width;
  return rate * value + 'vmin';
};

export default defineComponent({
  name: 'ToCanvas',
  components: {
    LoadingAnimation
  },
  props: {
    imageIdx: String,
    image: Object
  },
  data() {
    return {
      imageInfo: computed(() => this.image as ChapterImage),
      wrapWidth: isPC() ? 720 : window.innerWidth,
      wrapHeight: 0,
      ratio: 1,
      decodeUrl: '',
      reloadCount: 0,
      error: false
    };
  },
  methods: {
    resize: function () {
      this.wrapWidth = isPC() ? 720 : window.innerWidth;
      this.ratio = this.wrapWidth / this.imageInfo.value.width;
      this.wrapHeight = Math.floor(this.ratio * this.imageInfo.value.height);
    },
    onReload: function () {
      if (this.reloadCount < 3) {
        this.reloadCount++;
        this.error = false;
      } else {
        this.$q.dialog({
          component: AlertDialog,
          componentProps: {
            title: '',
            message:
              '読み込みに失敗しました。通信環境をご確認のうえリロードしてください。',
            ok: '確認'
          }
        });
      }
    },
    loadFail: function () {
      this.error = true;
    }
  },
  created() {
    this.resize();
    const { parameter, url } = this.image as ChapterImage;
    const imgUrl = Encrypto.AESDecoder(url, Encrypto.AESKey);
    this.decodeUrl = `${imgUrl}?${parameter}`;
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  setup(props, { emit }) {
    // const imageInfo: ComputedRef<ChapterImage> = computed(
    //   () => props.image as ChapterImage
    // );

    // const decodeUrl: Ref<string | null> = ref(null);
    const imgRef: Ref<HTMLImageElement | null> = ref(null);
    const divRef: Ref<HTMLDivElement | null> = ref(null);
    const showImg = ref(false);
    const innerWidth = window.innerWidth;

    // const setDecodeUrl = () => {
    //   const { parameter, url } = imageInfo.value;
    //   const imgUrl = Encrypto.AESDecoder(url, Encrypto.AESKey);
    //   decodeUrl.value = `${imgUrl}?${parameter}`;
    // };

    // load処理
    const toCanvas = (value) => {
      try {
        // 多分resize処理の可能性が高いですが
        // QA後問題がないとしたら消します
        // if (divRef.value && imgRef.value) {
        //   const canvas = imgToCanvas(imgRef.value);
        //   if (canvas?.canvas) {
        //     wrapWidth.value = canvas.clientWidth;
        //     wrapHeight.value = canvas.clientHeight;
        //     divRef.value.appendChild(canvas.canvas);
        //     const backgroundOption = `
        //       background: url('${decodeUrl.value}');
        //       background-size: ${wrapWidth.value}px ${wrapHeight.value}px;
        //     `;
        //     if (idx.value) {
        //       ContentsHelperService.imageStyleAdd(idx.value, backgroundOption);
        //     }
        //   }
        // }

        showImg.value = true; // loadが終わったら、変更
        emit('emitLoadCount', {
          height: imgRef.value?.clientHeight
        });

        // ここからエラーが発生した時の処理が必要
      } catch (e) {
        // alert(e);
        emit('emitErrorLoad', {});
      }
    };

    return {
      imgRef,
      showImg,
      divRef,
      innerWidth,
      toCanvas,
      convertPxToVw,
      LoadingAnimation
    };
  }
});
