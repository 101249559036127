import NotFound from '@/views/common/NotFound.vue';

export default [
    {
      path: '/campaign/',
      name: 'Campaign',
      component: () => import('@/views/campaign/Index.vue'),
      children: [
        {
          path: '',
          component: NotFound
        },
        {
          path: 'spring2024',
          name: 'Spring2024',
          component: () => import('@/views/campaign/spring2024/Index.vue')
        },
        {
          path: 'spring2024/app',
          name: 'Spring2024App',
          component: () => import('@/views/campaign/spring2024/Index.vue')
        },
        {
          path: 'error',
          name: 'CampaignError',
          component: () => import('@/views/campaign/Error.vue'),
        }
      ]
    }
  ];